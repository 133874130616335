import { useEffect, useMemo, useState } from "react";
import { createTheme, useMediaQuery } from "@mui/material";
import LegalbirdPaletteMode from "../types/LegalbirdPaletteMode";
import theme, { darkModePalette, lightModePalette } from "../theme/LegacyTheme";
import ReactGA from "react-ga4";

const useSelectedTheme = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<LegalbirdPaletteMode>("light");

  useEffect(() => {
    let storedTheme: string | null = localStorage.getItem("theme");

    if (!["light", "dark", "custom"].includes(storedTheme ?? "")) {
      localStorage.setItem("theme", "custom");
      storedTheme = "custom";

      ReactGA.event({
        category: "Theme",
        action: "Custom Theme is set to " + prefersDarkMode ? "dark" : "light",
        nonInteraction: true,
      });
    }

    if (storedTheme === "custom") {
      setMode(prefersDarkMode ? "dark" : "light");
      return;
    }

    setMode(storedTheme as LegalbirdPaletteMode);
  }, [prefersDarkMode]);

  // @ts-ignore
  const selectedTheme = useMemo(() => createTheme({ ...theme, ...getDesignTokens(mode) }), [mode]);

  return { mode, selectedTheme };
};

const getDesignTokens = (mode: LegalbirdPaletteMode) => {
  return mode === "light" ? lightModePalette : darkModePalette;
};

export { useSelectedTheme };
